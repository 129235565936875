import { FilterOptionsState } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import React, { useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { simpleMatch } from '../../../../utils';
import { ClickAwayListener } from '../../../utils/ClickAwayListener';
import { projectType } from './ProjectsPage';
import './SearchBar.scss';
import { OkButton } from '../../../navigation/ValidationButton/OkButton';

const colors = {
  pureWhite: '#FFFFFF',
  black: '#212121',
  red: '#FF0000',
}

const color = {
  searchBar: colors.pureWhite,
  searchBarError: colors.red,
  textField: colors.black,
}

type Props = {
  projects: projectType[];
}

const SearchBar = ({ projects }: Props) => {
  const navigate = useNavigate();

  const [searchedProject, setSearchedProject] = useState<string>('');
  const [borderColor, setBorderColor] = useState<string>(color.searchBar);
  const searchBarRef = useRef<HTMLDivElement>(null);
  const labelDefaultValue = 'Rechercher un projet';

  const [label, setLabel] = useState<string>(labelDefaultValue);



  const projectSearchList = useMemo<{ label: string }[]>(() => {
    return projects.map((project: projectType) => ({ label: project.title }))
  }, [projects]);

  const handleSearchValidation = (key?: React.KeyboardEvent<HTMLDivElement>) => {
    if (key?.code === 'Enter' || !key) {
      navigate(`/projects/${searchedProject}`);
    }
  }

  const handleSearchInputChange = (value: { label: string; }) => {
    setSearchedProject(value.label);
  }

  const handleFilterOptions = (
    options: { label: string }[],
    state: FilterOptionsState<{ label: string }>
  ) => {
    const inputStartsWith = (fixedValue: string) => fixedValue.toLowerCase().startsWith(state.inputValue.toLowerCase());
    const inputMatches = (fixedValue: string) => simpleMatch(fixedValue, state.inputValue);

    const newOptions = projects.map((project: projectType) => {
      let newOptionValue: { label: string } = { label: '' };

      project.stack.forEach((projectStack) => {
        if (state.inputValue.length && inputMatches(projectStack.title)) {
          newOptionValue.label = `${project.title}`;
        }
      })

      if (!newOptionValue.label.length && inputStartsWith(project.title)) {
        newOptionValue.label = `${project.title}`
      }
      return newOptionValue;
    })
      .filter((project: { label: string }) => project.label.length);
    setBorderColor(newOptions.length ? color.searchBar : color.searchBarError);
    return newOptions;
  }

  const sxStyles = {
    autoComplete: {
      backgroundColor: color.textField,
      '& .MuiAutocomplete-popupIndicator > .MuiSvgIcon-root': {
        color: borderColor
      },

      input: {
        color: color.searchBar
      },
      label: {
        color: color.searchBar,
        "&.Mui-focused": {
          color: borderColor
        }
      },
      "& .MuiOutlinedInput-root": {
        "&:hover fieldset": {
          borderWidth: '2px',
          borderColor: borderColor,
        },
        "&.Mui-focused fieldset": {
          borderColor: borderColor,
        },
        fieldset: {
          borderColor: borderColor,
        },
      },
    }
  }

  return (
    <div className='searchbar_container'>
      <Autocomplete
        disablePortal
        options={projectSearchList}
        filterOptions={handleFilterOptions}
        sx={{ width: '70vw' }}
        renderInput={(params) =>
          <ClickAwayListener ref={searchBarRef} onClickAway={() => setBorderColor(color.searchBar)}>
            <TextField
              {...params}
              variant='outlined'
              autoComplete='true'
              label={label}
              sx={sxStyles.autoComplete}
              onChange={() => setBorderColor(color.searchBar)}
            />
          </ClickAwayListener>}
        onChange={(event, value) => {
          if (value?.label) {
            handleSearchInputChange(value);
            setLabel('Hit enter');
          } else {
            setLabel(labelDefaultValue);
          }
        }}
        onKeyDown={(key: React.KeyboardEvent<HTMLDivElement>) => handleSearchValidation(key)}
      />
      <OkButton displayCondition={searchedProject.length > 0} onClickHandler={handleSearchValidation}>
        Ok
      </OkButton>
    </div>
  )
}

export default SearchBar
