import React, { Dispatch, SetStateAction } from 'react';
import { useNavigate } from 'react-router-dom';
import { StackItem, projectType } from '../pages/Projects/ProjectsPage/ProjectsPage';
import './ProjectCard.scss';

type Props = {
  project: projectType;
  select: Dispatch<SetStateAction<projectType>>;
}

const ProjectCard = ({ project, select }: Props) => {
  let navigate = useNavigate();

  const handleProjectClick = (e: React.MouseEvent<HTMLDivElement>) => {
    navigate("/projects/" + project.title);
  }

  return (
    <div
      onMouseEnter={() => select(project)}
      onClick={(e) => handleProjectClick(e)}
      className='project_card_grid_container'
    >
      <div
        className='project_card_item_background-image'
        style={{ background: `url(${project.previewUrl}) center / contain no-repeat` }}
      />
      <span className='project_card_item_title'>
        {project.title}
      </span>
      <div className='project_card_item_stack_container'>
        {project.stack.map((techno: StackItem, id: number) =>
          <span
            className='project_card_item_stack_item'
            key={project.id + '/stack/' + id + '/' + techno}
          >
            {techno.title}
          </span>)}
      </div>
    </div>
  )
}

export default ProjectCard;