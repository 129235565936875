import {
    cloneElement,
    forwardRef,
    useEffect
} from "react";

type ClickAwayListenerProps = {
    children: React.ReactElement;
    onClickAway: () => void;
}

export const ClickAwayListener = forwardRef(({ children, onClickAway }: ClickAwayListenerProps, ref) => {
    useEffect(() => {
        const handleClickAway = (event: MouseEvent) => {
            if (event.target && !isRefChildOfTarget(event.target as Element)) {
                onClickAway();
            }
        }
        document.addEventListener('click', handleClickAway);
        return (() => {
            document.removeEventListener('click', handleClickAway);
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const isRefChildOfTarget = (target: Element) => {
        const actualRef = ref as React.RefObject<HTMLElement>;
        if (!actualRef.current) return false;
        return !(target.contains(actualRef.current));
    }
    return cloneElement(children, { ref: ref as React.RefObject<HTMLElement> });
});