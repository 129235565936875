import { iconPaths } from "../../../../iconPaths";
import {
    gitHubUrl,
    linkedInProfileUrl,
    resumeUrl
} from "../../../../urls";
import "./Header.scss";
import SocialNetwork from "./SocialNetwork";

export const Header = () => {
    return (
        <div
            className='projects_page_social-network_container'
            style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}
        >
            <SocialNetwork
                iconPath={iconPaths.github}
                redirectUrl={gitHubUrl}
                text='GitHub'
            />
            <SocialNetwork
                iconPath={iconPaths.linkedin}
                redirectUrl={linkedInProfileUrl}
                text='LinkedIn'
            />
            <SocialNetwork
                iconPath={iconPaths.portrait}
                redirectUrl={resumeUrl}
                text='CV'
            />
        </div>
    );
}