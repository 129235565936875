import { useParams } from 'react-router-dom';
import { useAppSelector } from '../../../../state/hooks';
import ProjectVideo from '../../../Project/ProjectVideo';
import { projectType } from '../ProjectsPage/ProjectsPage';
import { notFound } from '../projectsSlice';
import './ProjectPage.scss';
import ProjectPageFooter from './ProjectPageFooter';
import ProjectPageHeader from './ProjectPageHeader';

const defaultProject = notFound;
const DEFAULT_STRING_VALUE = '';

/**
 * Composant qui prend une page, représentant un projet en fonction de son titre
 */

const ProjectPage = () => {
  const { projectTitle: param = DEFAULT_STRING_VALUE } = useParams();
  const projectStore = useAppSelector((state) => state.projectStore);

  const findProjectByTitle = (projects: projectType[], title: string) => {
    const titleToLower = title.toLowerCase();
    const project = projects.find((project: projectType) => project.title.toLowerCase().includes(titleToLower));
    return project || defaultProject;
  }

  const project = findProjectByTitle(projectStore.projects, param);

  return (
    <div className='project_page_container'>
      <ProjectPageHeader project={project} />
      <ProjectVideo project={project} />
      <ProjectPageFooter project={project} style={{ paddingTop: '1rem' }} />
    </div>
  )
}

export default ProjectPage
