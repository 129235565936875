import { useNavigate } from 'react-router-dom';
import { iconPaths } from '../../../../iconPaths';
import { projectType } from '../ProjectsPage/ProjectsPage';
import './ProjectPageHeader.scss';

type Props = {
  project: projectType
}

const ProjectPageHeader = ({ project }: Props) => {
  let navigate = useNavigate();

  const handleTechClick = (tech: { title: string; link?: string }) => {
    tech.link && window.location.replace(tech.link);
  }

  return (
    <div className='project_page_header'>
      <div className='project_page_social-network_container'>
        <svg
          className='social-network_button_svg'
          focusable='false'
          aria-hidden='true'
          viewBox='0 0 24 24'
          onClick={() => navigate('/')}
        >
          <path d={iconPaths.keyboardBackspace}>
          </path>
        </svg>
      </div>

      <span className='project_page_header_title'>
        {project.title}
      </span>
      <div className='project_page_stack'>
        {project.stack.map((tech: { title: string; link?: string }, id: number) => (
          <p key={'header/stack' + tech.link} onClick={() => handleTechClick(tech)}>
            {tech.title}
          </p>))}
      </div>
    </div>
  )
}

export default ProjectPageHeader