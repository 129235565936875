import './SocialNetwork.scss';

type Props = {
  iconPath: string;
  redirectUrl: string;
  text?: string;
}

const SocialNetwork = ({ iconPath, redirectUrl, text = '' }: Props) => {
  return (
    <div>
      <button
        className='social-network_button'
        onClick={() => window.location.href = redirectUrl}
        type='button'
      >
        <svg
          className='social-network_button_svg'
          focusable='false'
          aria-hidden='true'
          viewBox='0 0 24 24'
        >
          <path d={iconPath} />
        </svg>
        {text}
      </button>
    </div>
  )
}

export default SocialNetwork
