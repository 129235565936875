import axios from 'axios';
import qs from 'query-string';
import { projectType } from '../components/pages/Projects/ProjectsPage/ProjectsPage';
import * as routes from '../routes';

const populateProjects = () => {
  return (
    qs.stringify({
      populate: '*'
    }));
}

//Find a better place to import from.
export const MISSING_ITEM_IMAGE = 'missingItem.png';

const getLargeItemPreviewUrl = (item: any) => {
  return (
    item
      ?.attributes
      ?.Preview
      ?.data
      ?.attributes
      ?.formats
      ?.large
      ?.url ?? MISSING_ITEM_IMAGE
  );
}

//TODO:
//Create wrapper class for strapi items
// new StrapiItem(originalResponse...);
// 3/10
const transformResponse = (originalResponse: any) => {
  const transformedResponse: projectType[] = originalResponse.data.data.map((item: any) => {
    return {
      id: item.id,
      title: item.attributes.title,
      previewUrl: process.env.HOST || 'http://localhost:1337' + getLargeItemPreviewUrl(item),
      stack: item.attributes.stack.split('\n'),
      context: item.attributes.Context,
    }
  })
  return transformedResponse;
}

export const getProjects = async () => {
  return transformResponse(await axios.get(routes.projectsUrl + '?' + populateProjects()));
}
