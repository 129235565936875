import './Footer.scss';

export const Footer = () => {
    return (
        <div className='projects_footer'
            onClick={() => window.location.replace('mailto:olivier.laffon.dev@gmail.com')}
        >
            <span className="projects_footer_contact">
                Contact
            </span>
        </div>

    )
}