import { Dispatch, SetStateAction } from 'react';
import ProjectCard from '../../../Project/ProjectCard';
import './ProjectsDisplay.scss';
import { projectType } from './ProjectsPage';

type Props = {
  projectList: projectType[];
  setFocusedProject: Dispatch<SetStateAction<projectType>>;
}

export const ProjectsDisplay = ({ projectList, setFocusedProject }: Props) => {
  return (
    <div className='projects_display_container'>
      {projectList.map((project: projectType, id: number) =>
        <div key={'project/' + id + '/' + project.title}>
          <ProjectCard project={project} select={setFocusedProject} />
        </div>)}
    </div>
  )
}
